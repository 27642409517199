@import '../../styles/variables.scss';

.container.end {
    color: white;

    .animation {
        width: 87vw;
        height: 28vh;
        margin-top: 2vh;
        z-index: 10;
        position: relative;
        pointer-events: none;
    }

    canvas {
        background-color: transparent !important;
    }

    .disclaimer {
        padding: 0 4vh 3vh 4vh;
        margin-top: -2vh;
        font-size: 2.1vw;
        line-height: 2.5vw;
        text-align: justify;
    }

    div {
        p {
            font-family: "FilsonSoftRegular";
            font-size: 4.3vw;
            line-height: 5.5vw;

            &.more {
                font-family: 'Yrsa', serif;
                font-size: 5vw;
                line-height: 5.5vw;
            }
        }

        h1 {
            padding: 3vh 0;
            font-family: 'Yrsa', serif;
            font-weight: bold;
            font-size: 17vw;
            line-height: 17vw;
        }

        &.orange {
            height: 43vh;
            a {
                font-family: "FilsonSoftRegular";
                font-size: 5.8vw;
                line-height: 5.5vw;
                margin-bottom: 2vh;
                display: inline-block;
            }

        }

        &.purple {
            padding: 0;

            .flex {
                flex-direction: row;

                >div {
                    width: 50%;
                    padding-left: 10vw;
                    padding-top: 10vw;
                    padding-bottom: 10vw;

                    span {
                        font-family: 'Yrsa', serif;
                        font-size: 4.7vw;
                        line-height: 5.5vw;
                        display: inline-block;

                        &:last-child {
                            margin-bottom: 1vh;
                        }
                    }
                }
            }

            h5 {
                font-family: 'Yrsa', serif;
                font-size: 5vw;
                line-height: 5.5vw;
                font-weight: bold;
            }

            h1 {
                padding: 3vh 0;
                font-family: 'Yrsa', serif;
                font-weight: bold;
                font-size: 17vw;
                line-height: 17vw;
            }
        }
    }
}

.container.end.nl div {
    h1 {
        font-size: 14vw;
        line-height: 14vw;
    }

    &.orange a {
        font-size: 5vw;
    }
}
.container.end.en div {
    h1 {
        font-size: 14vw;
        line-height: 14vw;
    }
}