.button {
    font-family: "FilsonSoftBlack";
    border-radius: 200px;
    padding: 15px 40px 12px 40px;
    border: 2px solid black;
    display: flex;
    align-items: center;
    font-size: 20px;
    background-color: $background;
    transition: background 200ms;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none !important;

    &:hover {
        background-color: white;
    }

    &.next {
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        width: 80px;
        height: 80px;
        border: none;
        padding: 0;
        border-radius: 100%;

        &.purple {
            background-image: url('../images/svg/cim_fleche_mauve.svg');

            &.outline {
                background-image: url('../images/svg/cim_fleche_outline_mauve.svg');
            }
        }

        &.yellow {
            background-image: url('../images/svg/cim_fleche_jaune.svg');

            &.outline {
                background-image: url('../images/svg/cim_fleche_outline_jaune.svg');
            }
        }

        &.blue {
            background-image: url('../images/svg/cim_fleche_turquoise.svg');

            &.outline {
                background-image: url('../images/svg/cim_fleche_outline_turquoise.svg');
            }
        }

        &.orange {
            background-image: url('../images/svg/cim_fleche_rouge.svg');

            &.outline {
                background-image: url('../images/svg/cim_fleche_outline_rouge.svg');
            }
        }
    }
}