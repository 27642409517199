@import '../../styles/variables.scss';

.container.question {
    height: 100%;
    canvas {
        background-color: transparent !important;
    }

    h1 {
        padding: 0;
        font-family: 'Yrsa', serif;
        font-weight: bold;
        font-size: 20vw;

        span {
            display: inline-flex;
            margin-left: 5vw;
            width: 16vw;
            height: 16vw;
            font-size: 15vw;
            top: -2vw;
            position: relative;
            justify-content: center;
            align-items: center;

            &:before {
                display: block;
                content: "";
                width: 16vw;
                height: 16vw;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 100%;
                border: 1px solid white;
            }
        }
    }

    p.desc {
        font-family: "FilsonSoftRegular";
        font-size: 4.3vw;
        line-height: 5.5vw;
        margin-bottom: 5vw;
    }

    .next {
        display: inline-block;
        margin-bottom: 15vw;
    }

    .round-image {
        width: 40%;
        margin-left: 2vw;
        margin-right: 2vw;
        margin-bottom: 2vh;
    }

    .question-helper {
        font-family: 'Yrsa', serif;
        font-weight: bold;
        padding: 8vw 7vw 8vw 7vw;
        font-size: 5.5vw;
        line-height: 5.5vw;

        &.text-purple {
            color: $purple;
        }

        &.text-yellow {
            color: $yellow;
        }

        &.text-blue {
            color: $blue;
        }

        &.text-orange {
            color: $orange;
        }
    }

    .question-desc {
        font-family: 'Yrsa', serif;
        font-weight: bold;
        padding: 15vw 7vw 8vw 7vw;
        font-size: 5.5vw;
        line-height: 5.5vw;

        &.hard {
            padding: 22vw 7vw 8vw 7vw;
        }

        span {
            display: inline-block;
            width: 15vw;
            margin-right: 2vw;
            height: 2vh;
            position: relative;

            .animation-container {
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
    }

    .question-image {
        width: 100%;
    }

    .questions {
        font-family: "FilsonSoftBlack";
        padding: 5vw;
        display: flex;
        justify-content: center;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        li {
            border: 1px solid black;
            border-radius: 50px;
            padding: 15px 40px 12px 40px;
            margin-bottom: 2vh;
            vertical-align: middle;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            max-width: 400px;
            width: 100%;
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            outline: none !important;

            &.error {
                padding: 15px 0 0 0;
                margin-bottom: 0;
                border: none;
                color: red;
            }

            &.success {
                padding: 15px 0 0 0;
                margin-bottom: 0;
                border: none;
                color: #47ad44;
            }

            &.question-orange {
                border-color: $orange;
                color: $orange;

                &.selected {
                    background-color: $orange;
                    color: white;
                }
            }

            &.question-blue {
                border-color: $blue;
                color: $blue;

                &.selected {
                    background-color: $blue;
                    color: white;
                }
            }

            &.question-yellow {
                border-color: $yellow;
                color: $yellow;

                &.selected {
                    background-color: $yellow;
                    color: white;
                }
            }

            &.question-purple {
                border-color: $purple;
                color: $purple;

                &.selected {
                    background-color: $purple;
                    color: white;
                }
            }
        }
    }
    #bottom {
        display: inline-block;
        height:1px;
        width: 100%;
        position: absolute;
        bottom: 0;
    }
}