@import '../../styles/variables.scss';

.container.map {
    position: relative;
    .map-container {
        z-index: 2;
        margin-bottom: 5vh;
        margin-top: 5vh;
        width: 95vw;
        position: relative;
    }

    .button.go {
        margin-bottom: 5vh;
        display: block;
    }
    canvas {
        background-color: transparent !important;
    }

    padding: 0;

    .header {

        >div {
            width: 100%;
            padding-top: 5vh;
            background-color: $purple;

            &:first-child {
                background-color: $yellow;
                height: auto;
            }
            &.subtitle-container {
                position: relative;
                &:after {
                    display: block;
                    content: "";
                    height: 26vh;
                    position: absolute;
                    top: 99%;
                    left: 0;
                    background-color: $purple;
                    width: 100%;
                    z-index: 0;
                }
            }
        }

        .animation {
            width: 80%;
            padding-left: 6vw;
        }

        h1 {
            color: white;
            font-family: 'Yrsa', serif;
            font-weight: bold;
            font-size: 16vw;
            padding: 3vw 6vw 10vw 6vw;
            line-height: 16vw;
        }

        h2 {
            color: white;
            font-family: 'Yrsa', serif;
            font-weight: bold;
            font-size: 7.5vw;
            padding: 3vw 6vw 0vw 6vw;
            line-height: 8vw;
        }

        p {
            font-family: "FilsonSoftRegular";
            color: white;
            padding: 3vw 6vw 0vw 6vw;
            font-size: 4.7vw;
            line-height: 6.2vw;

            span {
                color: black;
                text-transform: uppercase;
                font-family: "FilsonSoftBlack";
            }
        }

    }

}

.nl.container.map .header h1 {
    font-size: 15vw;
}

// tablet landscape
@media (min-width: $breakpoint-tablet) {
    .container.map .header {
        display: flex;

        &>div {
            width: 50%;
            &.subtitle-container:after {
                display: none;
            }
        }

        h1 {
            font-size: 6vw;
            line-height: 6vw;
            padding: 3vw;
        }

        h2 {
            font-size: 3.6vw;
            line-height: 4.5vw;
            padding: 3vw 3vw 0vw 3vw;
        }

        p {
            font-size: 2.7vw;
            line-height: 3.5vw;
            padding: 3vw 3vw 0vw 3vw;
        }

        .animation {

            padding-left: 3vw;
        }
    }
}