@import '../../styles/variables.scss';

.container.home {
    height: 100vh;
    overflow: hidden;

    .content {
        z-index: 1;
        position: relative;
        padding: 10vw;
        height: 100%;

        @media (min-width: $breakpoint-tablet) {
            padding: 2vw;
        }
    }

    .worms {
        position: absolute;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        z-index: 0;
    }

    #blue {
        background-image: url('../../images/svg/cim_ver_bleu.svg');
        width: 63vw;
        height: 52vw;
        top: -22vw;
        right: -16vw;
    }

    #yellow {
        background-image: url('../../images/svg/cim_ver_jaune.svg');
        width: 110vw;
        height: 45vw;
        top: 48vh;
        left: -33vw;
    }

    #orange {
        background-image: url('../../images/svg/cim_ver_rouge.svg');
        width: 70vw;
        height: 57vw;
        top: 56vh;
        right: -14vw;
    }

    #purple {
        background-image: url('../../images/svg/cim_ver_mauve.svg');
        width: 83vw;
        height: 58vw;
        bottom: -14vw;
        left: -16vw;
    }

    #logos {
        margin-bottom: 5vh;
        display: block;

        img {
            width: 135px;
            height: auto;
        }
    }

    h1 {
        position: relative;

        @media (min-width: $breakpoint-tablet) {
            width: 600px;
        }

        .title-container {
            display: block;

            >span {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
            }
        }

        .title1 {
            font-family: 'Yrsa', serif;
            font-weight: bold;
            font-size: 6vw;
            margin-bottom: 0.5vh;
            display: inline-block;

            @media (min-width: $breakpoint-tablet) {
                font-size: 40px;
            }
        }

        .title2 {
            display: block;
            font-family: "FilsonSoftBlack";
            width: 100%;
            font-size: 11.8vw;

            @media (min-width: $breakpoint-tablet) {
                font-size: 80px;
            }
        }

        .title3 {
            font-family: "FilsonSoftBlack";
            font-size: 7vw;
            margin-right: 1vw;
            position: relative;
            top: 0.1vh;

            @media (min-width: $breakpoint-tablet) {
                font-size: 60px;
            }
        }

        .title4 {
            font-family: "FilsonSoftBlack";
            font-size: 11.8vw;

            @media (min-width: $breakpoint-tablet) {
                font-size: 80px;
            }
        }

        .title5 {
            font-family: "FilsonSoftRegular";
            font-size: 3vw;
            display: inline-block;
            width: 100%;
            text-align: right;

            @media (min-width: $breakpoint-tablet) {
                font-size: 20px;
            }
        }

        .mushrooms {
            background-image: url('../../images/png/home/mushrooms.png');
            position: absolute;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            height: 20vw;
            width: 15vw;
            top: 0;
            right: 3vw;
        }
    }

    #link {
        font-family: "FilsonSoftRegular";
        position: absolute;
        bottom: 10vw;
        right: 10vw;
        color: black;
        font-size: 3vw;

        @media (min-width: $breakpoint-tablet) {
            font-size: 20px;
            bottom: 2vw;
            right: 2vw;
        }
    }

    #start-link {
        margin-top: 5vh;
        display: flex;
        justify-content: center;
    }
}

.en.container h1 {
    width: 500px;

    .mushrooms {
        top: 1vw;
        right: -2vw;
    }
}

.nl.container h1 {
    .mushrooms {
        top: 12vw;
        right: 0;
        width: 12vw;
        background-image: url('../../images/png/home/mushrooms-small.png');
    }
}

@media (min-width: $breakpoint-tablet) {
    .container {
        #start-link {
            height: 5vh;
            font-size: 30px;
            margin-top: 24vh;
        }
    }

    .fr.container h1 {
        .mushrooms {
            height: 90px;
            width: 100px;
            top: 22px;
            right: 69px;
        }
    }

    .en.container h1 {
        .title4 {
            margin-left: 50px;
        }

        .mushrooms {
            height: 90px;
            width: 100px;
            top: 22px;
            right: 5px;
        }
    }

    .nl.container h1 {
        .mushrooms {
            height: 90px;
            width: 100px;
            top: 109px;
            right: 9px;
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .nl.container h1 .title2 {
        font-size: 9.6vw;
    }

    .en.container h1 {
        width: 100%;

        .title2 {
            font-size: 13.5vw
        }

        .title4 {
            margin-left: 10vw;
            font-size: 13.5vw
        }
    }
}

// tablet portrait
@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-tablet-landscape) {
    .container.home #yellow {
        top: 41vh;
    }
}

// tablet landscape
@media (min-width: $breakpoint-tablet-landscape) {
    .container.home {
        #blue {
            width: 30vw;
            top: -23vw;
            right: -8vw;
        }

        #yellow {
            width: 60vw;
            top: 25vh;
            left: -20vw;
        }

        #orange {
            width: 35vw;
            top: 25vh;
            right: -7vw;
        }

        #purple {
            width: 45vw;
            bottom: -24vw;
            left: -12vw;
        }
    }
}

// tablet landscape & desktop
@media (min-width: $breakpoint-dekstop) {
    .container.home .content {
        padding: 2vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .container.home #yellow {
        top: 20vh;
    }

    .container.home #logos {
        position: absolute;
        top: 2vw;
        left: 2vw;

        img {
            width: 200px;
        }
    }

    .container.home .link-container {
        position: absolute;
        bottom: 2vw;
        left: 0;
        width: 100%;
    }
    .container.home #link {
        position: relative;
        bottom: auto;
        right: auto;
    }

    .container.home {
        #blue {
            width: 500px;
            top: -161px;
            right: -126px;
            height: 410px;
        }

        #yellow {
            width: 750px;
            top: 39vh;
            left: -230px;
            height: 260px;
        }

        #orange {
            width: 500px;
            top: 40vh;
            right: -100px;
            height: 330px;

        }

        #purple {
            width: 500px;
            bottom: -34px;
            left: -100px;
            height: 250px;
        }
    }
}