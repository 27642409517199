body {
    background-color: $background;
    // height: 100vh;
    width: 100vw;
    overflow-x: hidden;
}
.flex {
    display: flex;
    &.center {
        justify-content: center;
    }
}

.purple {
    color: white;
    width: 100%;
    padding: 3vh 5vh;
    background-color: $purple;
}
.yellow {
    color: white;
    width: 100%;
    padding: 3vh 5vh;
    background-color: $yellow;
}
.blue {
    color: white;
    width: 100%;
    padding: 3vh 5vh;
    background-color: $blue;
}
.orange {
    color: white;
    width: 100%;
    padding: 3vh 5vh;
    background-color: $orange;
}

.container {
    background-color: $background;
    width: 100vw;
    position: relative;
    &.with-top-bar {
        // height: 100vh;
        padding-top: 60px;
    }
}
.animation-container {
    pointer-events: none;
}
// tablet landscape
@media (min-width: $breakpoint-tablet) {
    .flex {
        .purple {
            width: 50%;
        }
        .yellow {
            width: 50%;
        }
        .blue {
            width: 50%;
        }
        .orange {
            width: 50%;
        }
    }
}