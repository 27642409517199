.lang-switch {
    position: absolute;
    z-index: 10;
    .lang-container{
        padding: 5px;
        background-color: white;
        border-radius: 10px;
        font-family: "FilsonSoftRegular";
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        li {
            padding: 10px;
            display: none;
        }
        .selected {
            order: -1;
            font-family: "FilsonSoftBlack";
            display: block;
        }
        &.open {
            li {
                display: block;
            }
        }
    }
}