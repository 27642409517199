.difficulty-switch {
    position: absolute;
    z-index: 10;
    display: flex;
    .button{
        margin-right: 1vw;
        justify-content: center;
        font-size: 14px;
        padding: 10px 20px 6px 20px;
        text-transform: uppercase;
        border: none;
        text-align: center;
        background-color: transparent !important;
        font-family: "FilsonSoftRegular";
        &.active {
            border: 1px solid black;
            font-family: "FilsonSoftBlack";
        }
    }
}